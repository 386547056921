<script>
export default {
    name: 'RechargeFormOnline',
    data() {
        return {

        }
    },
    props: [
        'currentSelectPay',
        'rechargeAmount',
    ],
    inject:[
    ],
    methods: {
        select(item, result, justCheck) {
            var related=result.filter(item=>{
                return item.checked    
            })
            if (related.length>0) {
                related.forEach(item=>{
                    item.checked=false;
                })
            }
            item.checked=true;                

            if (justCheck) {
                this.currentSelectPay.amount=item.amount
            }else {
                this.currentSelectPay.item=item;    
                this.currentSelectPay.id=item.id
            }
        },
        selected(event){
            event.target.blur();
            this.$forceUpdate();
        }
    },
    mounted() {
    },
    computed:{
    },
    created(){
        var that=this;
        if ( that.currentSelectPay.item && that.currentSelectPay.item.need_params && that.currentSelectPay.item.need_params[0].input_type==='select' ) {
            that.currentSelectPay.need_params[ that.currentSelectPay.item.need_params[0].key ]='default'
        }
    },
}
</script>

<template>
    <div class="form" v-if="_TEMPLATE==='template-3' && currentSelectPay.item">
        <ul class="amount">
            <li 
                v-for="item in rechargeAmount"
                @click="select(item,rechargeAmount,true)"
                :class="{highlight:item.checked}"
                >
                ¥ {{item.amount}}
            </li>
        </ul>
        <div class="fieldset">
            <h6>
                <img :src="require('@@/assets/'+_TEMPLATE+'/images/recharge/icons/money.png')" />
            </h6>
            <div class="standalone">
                <label>{{ getWord(['recharge', 'amount_of_betting']) }}</label>
                <input type="text" :placeholder="getWord('charge_money')+currentSelectPay.item.min+'~'+currentSelectPay.item.max" v-model="currentSelectPay.amount" />            
            </div>
        </div>
        <div class="fieldset" v-if="currentSelectPay.item.need_params&&currentSelectPay.item.need_params.filter(item=>{return item.type===2})" v-for="item in currentSelectPay.item.need_params&&currentSelectPay.item.need_params.filter(item=>{return item.type===2})">
            <h6>
                <img :src="require('@@/assets/'+_TEMPLATE+'/images/recharge/icons/money.png')" />
            </h6>
            <div class="standalone">
                <label>{{ item.name_text }}</label>
                <input v-if="item.input_type=='text'" type="text" v-model="currentSelectPay.need_params[item.key]" />
                <select v-if="item.input_type=='select'" v-model="currentSelectPay.need_params[item.key]" :class="{ 'default':currentSelectPay.need_params[item.key]==='default' }" @change="selected($event)">
                    <option disabled="true" value="default">{{ getWord('select') }}</option>
                    <option v-for="(_item,index) in item.select_value" :value="_item.key">{{ _item.name }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<style lang='scss' scoped>
@import "@@/assets/style/_variables";
.form {

    &.template-3 {
        margin: 0 -.25rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        background-color: #eeeeee;

        .fieldset {
            display: flex;
            align-items: center;
            padding: 0;
            border:0;

            &:first-child {
                border-top:0;
            }

            label {
                width: 1.8rem;
                font-size: .28rem;
                line-height: 1;
            }

            input {
                width: 4.7rem;
                font-size: .28rem;
                line-height: 2;
                padding: 0.05rem .25rem;
                box-sizing: border-box;

                &:disabled {
                    background-color: transparent;
                    width: 3.3rem;
                }
            }

            select {
                width: 4.7rem;
                border: 0;
                background-color: #ffffff;
                padding: .15rem;
            }

            > h6 {
                width: .4rem;
                padding: 0 .25rem;

                .iconfont {
                    color: #EC2829;
                }

                img {
                    height: .3rem;
                    vertical-align: middle;
                }
            }

            > div {
                border-bottom: 1px solid #d0d0d0;
                width: 100%;
                padding: 0.1rem 0;
                position: relative;

                &.standalone {
                    display: flex;
                    align-items: center;
                    border-top: 1px solid #d0d0d0;

                    select {

                        &.default {
                            color: #666666;
                        }
                    }

                    label {
                        color: #5F646E;
                    }
                }

                &.select {                                

                    .icon-back {
                        position: absolute;
                        top:50%;
                        transform: rotateZ(270deg) translateY(-.5rem) translateX(50%);
                        transform-origin: center;
                        opacity: .4;
                    }
                }
            }

            .copy {
                background-color: #EDB93F;
                display: inline-block;
                font-size: .28rem;
                color: #ffffff;
                text-align: center;
                white-space: nowrap;
                padding: .14rem .45rem;
                border-radius: 3px;
                position: absolute;
                right: .1rem;
            }
        }

        ul {

            &.amount {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: .25rem;

                li {
                    margin:0;
                    margin-bottom: .125rem;
                }
            }
        }
    }

    ul {

        &.amount {
                    
            li {
                width: 23%;
                display: inline-block;
                box-sizing: border-box;
                padding: .075rem .125rem;
                border: 1px solid #cccccc;
                text-align: center;
                margin: 2% 1%;
                font-size: .36rem;

                &.highlight {
                    color: $TEMPLATE2-theme-color;
                    border-color: $TEMPLATE2-theme-color;
                }
            }
        }   
    }

    button {

        &.submit {
            font-size: .3rem;
            display: block;
            background-color: $TEMPLATE2-theme-color;
            color: #fff;
            border: 0;
            width: 98%;
            margin: 0 auto;
            margin-top: .25rem;
            padding: .2rem 0;
        }
    }

    > input {
        width: 98%;
        border: 1px solid #cccccc;
        padding: .25rem;
        box-sizing: border-box;
        margin: .25rem 1%;

        &::placeholder {
           color: #cccccc;
        }
    }

    fieldset,
    .fieldset {
        border:0;
        border-bottom:1px solid #f5f5f9;
        font-size: .3rem;
        padding: .25rem 0;

        &:first-child {
            border-top:1px solid #f5f5f9;
        }

        label {
            width: 1.6rem;
            display: inline-block;
        }

        input {
            font-size: .3rem;
            border:0;
            width: 5rem;
        }
    }
}
</style>